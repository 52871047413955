import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Frem = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const token = 'YOUR_SIGNED_TOKEN';
    const embedKey = 'YOUR_EMBED_KEY';

    useEffect(() => {
        function handleIframeReady(event) {
            if (event.data !== 'READY') return;
            const targetWindow = window.frames['finchat'];
            if (!targetWindow) return;
            targetWindow.postMessage({ token }, 'https://enterprise.finchat.io');
        }

        window.addEventListener('message', handleIframeReady);

        return () => window.removeEventListener('message', handleIframeReady);
    }, [token]);
    return (
        <div style={{ background: '#1A1A1A' }}>
            <br />
            <div class='d-flex align-items-center justify-content-center mt-5' style={{ height: '100vh', width: '100%' }}>
                <div class='col-11 m-auto'>
                    <button class='btn btn-outline-light rounded-5 fs-5 fwr px-4 ' data-aos="fade-down">Lorem Ipsum Dolor Sit AMet</button>
                    <div class='col-12 m-auto sumBotShadow' data-aos="fade-up">
                        <img src={require('../Images/framBg.png')} style={{ width: '100%' }} alt="" />
                    </div>
                    <div class='sumBotTitle' >
                        <p class='mfs-7 fwb org' data-aos="fade-in" >SumBot3.0</p>
                        <p class='mfs-7 fwr text-white' data-aos="fade-up">AI-Enabled Financial Research</p>
                        <p class='fs-5 fwt col-10 m-auto tJustify text-white' data-aos="fade-up">Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.</p>
                        <button class='sumBtn mfs-4 rounded-5 mt-3' >Log in</button>

                    </div>
                </div>
            </div>

            <div class='col-10 m-auto' data-aos="zoom-in-up">
                <iframe
                    name="finchat"
                    src={`https://enterprise.finchat.io/${embedKey}`}
                    style={{ border: 'none', width: '100%', height: '80vh' }}
                    class='rounded-5 '
                />
            </div>

            <br />
        </div>
    )
}

export default Frem