import React, { useEffect, useState } from 'react'
import ScrollTrigger from 'react-scroll-trigger'
import Typewriter from 'typewriter-effect'
import CountUp from 'react-countup';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomeScroll from './HomeScroll';
import Services from './Services';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import HomeAbout2 from './HomeAbout2';
import HomeFooterTop from './HomeFooterTop';
import ServiceSmall from './ServiceSmall';
import './home.css'
import '../../App.css'
import { useNavigate } from 'react-router-dom';
import HomeAbout3 from './HomeAbout3';


const homeData = {
    slider:
    {
        title: `<p>BEST THINGS COME IN <span class='fw-bold'>3</span> </p> `,
        text: "YOU US MARKETS"
    }
    ,
    services: [
        {
            title: "Specially Curated Trading Environment",
            text: "Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art technology, ensuring you trade with confidence and security.",
            svg: ''
        },
        {
            title: "Automated Copy Trading with Global Experts ",
            text: "Gain access to over 500 expert traders from around the world. Review their strategies, performance history, and rankings before committing as little as $200 per trader. Diversify your investments across different asset classes, traders and strategies. ",
            svg: ''
        },
        {
            title: "Meet SumBot3.0 - AI-Enabled Financial Research",
            text: "Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.",
            svg: ''
        }
    ],
    why: [
        {
            title: "Why 3SUM?",
            text: "3Sum was created out of a simple realization: most trading platforms cater to advanced traders, leaving everyday people feeling overwhelmed and excluded. We noticed that while many are interested in exploring the capital markets, they're often discouraged by the complexity and jargon of traditional trading tools. They promise big returns and advanced tools, but as we dug deeper, one thing became crystal clear-these platforms are designed for the pros, the full-time traders who already speak the language of the markets."
        }
    ],
    counterCart: [
        {
            title: "Years Founder’s <br/> Experience",
            text: "20",
            extra: ''
        },
        {
            title: "Client <br/> Interactions",
            text: "100",
            extra: 'k'

        },
        {
            title: "Hours <br/> Learning",
            text: "50",
            extra: 'k'

        },

    ],
    ideas: {
        title: "Let’s turn your ideas into reality with us",
        ideas1: [
            "Fx-Pro",
            "CMC Markets",
            "How to Trade",
            "Wallstreet Coach",
            "Finchat",

        ],
        ideas2: [
            "Wallstreet 101",
            "Forex UPS",
            "FINLATO",
            "Track a Trade",
            "Mark Dickinson",
        ]

    },
    savings: {
        title: "Why risk your entire capital for the sake of minor savings?",
        cart: [
            {
                title: "Level up your portfolio with us."
            }
        ]
    }

}

const Home = () => {

    const [counterOn, setCounterOn] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY / 1000);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const navigate = useNavigate()
    return (
        <div>
            <div className='homeHero'>
                <div class=' col-11 m-auto'>
                    <p class='mfs-7  fwt' data-aos="fade-in" dangerouslySetInnerHTML={{ __html: homeData?.slider?.title }}></p>
                    <div className='mfs-8 fweb org'>
                        <Typewriter

                            options={{
                                strings: [homeData?.slider?.text],
                                autoStart: true,
                                loop: false, // Ensures the text is typed only once
                                delay: 200,
                                cursor: '', // Hides the blinking cursor
                                html: true,
                            }}
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(homeData?.slider?.text)
                                    .callFunction(() => {
                                        // Optionally perform additional logic after typing completes
                                        // For example, remove the cursor after the typing finishes
                                        const cursor = document.querySelector('.Typewriter__cursor');
                                        if (cursor) {
                                            cursor.style.display = 'none';
                                        }
                                    })
                                    .start();
                            }}
                        />
                    </div>
                    <button class='sumBtn mfs-4 rounded-5 mt-3' onClick={() => navigate('/log-in')}>Log in</button>
                    <div class='arrows'>
                        <p class='d1 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                        <p class='d2 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                        <p class='d3 fs-1'><i class="bi bi-caret-down-fill"></i> </p>
                    </div>
                </div>
            </div>


            <HomeScroll />

            <div class='servicePc mt-5'>
                <Services />
            </div>

            <div class='serviceMobile'>
                <ServiceSmall />
            </div>

            <div class='homeAboutPC'>

                <HomeAbout2 />
            </div>
            <div class='homeAboutMB'>

                <HomeAbout3 />
            </div>


            <div class='row col-11 m-auto gap-lg-0 gap-5 mt-3'>
                {homeData?.counterCart?.map((el) => {
                    return (
                        <div class='col-lg-4'  >
                            <div class='col-11  rounded-top-5 h-100 text-start p-2 m-auto' style={{ background: '#f2f2f2' }}>
                                <div class='col-11 m-auto'>
                                    <p class='mfs-6 fwm my-3' dangerouslySetInnerHTML={{ __html: el?.title }}></p>
                                </div>
                                <div class='text-end d-flex align-items-center justify-content-end mfs-8 '>
                                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                        <p class='mfs-8 fwsb'  ><span  >{counterOn && <CountUp start={0} end={el.text} delay={0} />}</span></p>
                                    </ScrollTrigger>
                                    <p class='fwsb'>
                                        {el?.extra}+
                                    </p>
                                </div>

                            </div>
                        </div>
                    )
                })}
            </div>

            <div class='col-11 m-auto my-5  bg-blackGray rounded-4' style={{ overflow: 'hidden' }}>
                <br />
                <div class='text-start text-white'>
                <div class='col-11 m-auto text-start text-white'> 
                    <div class='col-md-6 text-start fbnr '>
                        <p class='mfs-7 fbnr fweb' > Our Partner</p>
                    </div>
                    </div>

                    <div class='ourPartnerPc'>
                        <ParallaxProvider>
                            <Parallax translateX={[-50, 50]}>
                                <div class="d-flex gap-4 mt-4"  >
                                    {homeData?.ideas?.ideas1?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }
                                    
                                </div>
                            </Parallax>
                        </ParallaxProvider>
                        <ParallaxProvider>
                            <Parallax translateX={[50, -50]} >
                                <div class="d-flex gap-4 mt-4 justify-content-end"  >
                                    {homeData?.ideas?.ideas2?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })

                                    }
                                </div>
                            </Parallax>
                        </ParallaxProvider>
                    </div>

                    <div class='ourPartnerMb '>
                        <div class="marquee">
                            <div class="marquee__item">
                                <>
                            {homeData?.ideas?.ideas1?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt rounded-5 mx-3 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }
                                        {homeData?.ideas?.ideas2?.map((e) => {
                                        return (
                                            <button class='btn btn-outline-light fbnt mx-3 rounded-5 fs-4 text-nowrap px-5 fwm '>
                                                {e}
                                            </button>
                                        )
                                    })
                                    }
                                </>
                                    
                           
                            </div>
                        </div>
                    </div>

                    <div class='col-11 m-auto'>

                    <button class='sumBtn mfs-4 mt-5  rounded-5' onClick={() => navigate('/log-in')}>Log in</button>
                    </div>

                </div>
                <br />
            </div>
            <div class='col-11 m-auto'  >
                <p class='fbnh mfs-7 org fwh my-5'>Why risk your entire capital for the sake of minor savings?</p>
            </div>
            <HomeFooterTop />
            <br />
        </div>
    )
}

export default Home