import React, { useEffect, useRef } from 'react';
import './homeAbout2.css';
import '../../App.css'
import { useNavigate } from 'react-router-dom';
 
 
 


const HomeAbout2 = () => {
    const stickyRef = useRef(null);
    const stickyParentRef = useRef(null); 
    const firstDivRef = useRef(null);
    const secondDivRef = useRef(null);
    const thirdDivRef = useRef(null);

    useEffect(() => {
        const horizontalScroll = () => {
            const sticky = stickyRef.current;
            const stickyParent = stickyParentRef.current;

            if (!sticky || !stickyParent) return;

            const scrollWidth = sticky.scrollWidth;
            const verticalScrollHeight =
                stickyParent.getBoundingClientRect().height -
                sticky.getBoundingClientRect().height;

            const stickyPosition = sticky.getBoundingClientRect().top;
            if (stickyPosition > 1) {
                return;
            } else {
                const scrolled = stickyParent.getBoundingClientRect().top;
                sticky.scrollLeft = (scrollWidth / verticalScrollHeight) * -scrolled * 0.80;
            }
        }; 
        document.addEventListener("scroll", horizontalScroll);   
        return () => {
            document.removeEventListener("scroll", horizontalScroll); 
        };
    }, []);

    useEffect(() => {
        const down = document.querySelector(".firstDivDown");
        const up = document.querySelector(".firstDivUp");
        const text = document.querySelector(".firstDivText"); 
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setTimeout(()=>{

                    down.classList.add("active")
                    up.classList.add("active")
                    text.classList.add("active") 
                },500)
            } else {
                down.classList.remove("active"); 
                up.classList.remove("active"); 
                text.classList.remove("active");  
            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (firstDivRef.current) {
          observer.observe(firstDivRef.current);
        }
        return () => {
          if (firstDivRef.current) {
            observer.unobserve(firstDivRef.current);
          }
        };
      }, [firstDivRef]);

      useEffect(() => {
        const right = document.querySelector(".secondDivRight");
        const left = document.querySelector(".secondDivLeft");
        const text = document.querySelector(".secondDivText"); 
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setTimeout(()=>{

                    right.classList.add("active")
                    left.classList.add("active")
                    text.classList.add("active") 
                },500)
            } else {
                right.classList.remove("active"); 
                left.classList.remove("active"); 
                text.classList.remove("active");  
            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (secondDivRef.current) {
          observer.observe(secondDivRef.current);
        }
        return () => {
          if (secondDivRef.current) {
            observer.unobserve(secondDivRef.current);
          }
        };
      }, [secondDivRef]);

      useEffect(() => {
        const right = document.querySelector(".thirdDivRight");
        const left = document.querySelector(".thirdDivLeft");
        const text = document.querySelector(".thirdDivText"); 
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                setTimeout(()=>{

                    right.classList.add("active")
                    left.classList.add("active")
                    text.classList.add("active") 
                },500)
            } else {
                right.classList.remove("active"); 
                left.classList.remove("active"); 
                text.classList.remove("active");  
            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (thirdDivRef.current) {
          observer.observe(thirdDivRef.current);
        }
        return () => {
          if (thirdDivRef.current) {
            observer.unobserve(thirdDivRef.current);
          }
        };
      }, [thirdDivRef]);

    const navigate = useNavigate()

    return (
        <div class='mt-5'>

            <div className="sticky-parent" ref={stickyParentRef}>
                <div className="sticky" ref={stickyRef}>
                    <div className="horizontal">
                        <div className="dim" style={{ background: '#ffffff' }}> 
                            <div className='firstDiv' ref={firstDivRef}>
                                <div className='firstDivDown'   >
                                    <img src={require('../../Images/downArow.png')} alt="" />
                                </div>
                                <div className='firstDivUp' >
                                    <img src={require('../../Images/upArow.png')} alt="" />
                                </div>
                                <div className='firstDivText' >
                                    <p class='mfs-8 fwh'>   Lets do markets, together!</p>

                                </div>  
                                <div class='col-lg-9 col-10' style={{zIndex:'1'}}>
                                    <p class='mfs-8 fweb text-white'>WHY 3SUM?</p>
                                    <p class='tJustify mfs-4 fwsb'>3Sum was created out of a simple realization: most trading platforms cater to advanced traders, leaving everyday people feeling overwhelmed and excluded. We noticed that while many are interested in exploring the capital markets, they're often discouraged by the complexity and jargon of traditional trading tools. They promise big returns and advanced tools, but as we dug deeper, one thing became crystal clear-these platforms are designed for the pros, the full-time traders who already speak the language of the markets.</p>
                                </div> 
                            </div>  
                        </div>
                         <div className="dim" style={{ background: '#402E32' }}>


                            <div className='thirdDiv ' ref={thirdDivRef}>
                                <div className='thirdDivRight'  >
                                    <img src={require('../../Images/right.png')} alt="" />
                                </div>
                                <div className='thirdDivLeft' >
                                    <img src={require('../../Images/left.png')} alt="" />
                                </div>
                                <div className='thirdDivText' >
                                    <p class='mfs-8 fweb '> HOW TO PARTICIPATE</p>
                                </div>

                                <div class='col-lg-11 row gap-lg-0  gap-2  justify-content-center' style={{zIndex:'1'}}>
                                    <div class='col-lg-4 my-lg-1'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>01</p>
                                                <p class='htp2 fweb'>SIGN-UP & GAMIFIED ASSESSMENT</p>
                                                <p class='htp3 fwel '>create your account on 3Sum.</p>
                                                <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Log in</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class='col-lg-4 my-lg-5'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>02</p>
                                                <p class='htp2 fweb'>CUSTOMIZED TRADING ENVIROMENT</p>
                                                <p class='htp3 fwel'>Access a curated environment with services like copy trading.</p>
                                                <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Log in</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-lg-4 my-lg-0'>
                                        <div class='col-11 rounded-4 text-start m-auto ' style={{ border: '1px solid #000',zIndex:'1' }}>
                                            <div class='col-11 m-auto my-3'>
                                                <p class='htp1 fweb'>03</p> 
                                                <p class='htp2 fweb'>ACCOUNT ACTIVATION & PERSONALIZED SERVICE</p>
                                                <p class='htp3 fwel'>Fund your account to activate your personalized Live Signals, Copy Trading etc. Services.</p>
                                                <button class='sumBtn mfs-3 rounded-5 mt-2'  onClick={()=>navigate('/log-in')}>Log in</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dim" style={{ background: '#FFF5F0' }}>

                            <div className='secondDiv' ref={secondDivRef}>

                                <div className='secondDivRight'  >
                                    <img src={require('../../Images/right.png')} alt="" />
                                </div>
                                <div className='secondDivLeft' >
                                    <img src={require('../../Images/left.png')} alt="" />
                                </div>
                                <div className='secondDivText' >
                                    <p class='mfs-8 text-nowrap fwh'>   Lets do markets, together!</p>
                                </div>
                               
                                <div class='col-lg-9 col-10' style={{zIndex:'1'}}>
                                    <p class='mfs-8 fweb text-white'>WHAT IS 3SUM?</p>
                                    <p class='tJustify mfs-4 fwsb'>3Sum is a revolutionary platform designed to make trading and investing accessible to everyone, especially beginners. Unlike traditional platforms that cater to advanced traders with complex tools, 3Sum simplifies the experience, offering all the essential resources you need to start your trading journey in one place. We've partnered with some of the most reputable, regulated platforms worldwide to create a secure, user- friendly and a customized environment that adapts to your level of experience. With 3Sum, you don't need to be an expert to make smart investments. </p>
                                </div>

                            </div>


                        </div>
                       
                        <div className="dim">
                            <div className='forthDiv'>
                                <div class='col-lg-9 col-11'>
                                    <p class='mfs-8 fwt  '  style={{ color: "#EDEDED" }}>ARE YOU <br /><span class='fweb ctaText' style={{ color: '#FF823C' }}>PENNY WISE</span> <br />  AND <br /> <span class='fweb ctaText' style={{ color: '#FF823C' }}>POUND FOOLISH? </span></p>
                                    <button class='sumBtn mfs-4 my-4  rounded-5'  onClick={()=>navigate('/log-in')}>Log in</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default HomeAbout2;
