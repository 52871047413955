import './App.css';
import Home from './componets/home/Home';
import Footer from './componets/Footer';
 
import LogIn from './componets/Login/LogIn';
import Navbar from './componets/Navbar';
import { Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Invest1 from './componets/invest/Invest1';
import Invest2 from './componets/invest/Invest2';
import Invest3 from './componets/invest/Invest3';
import Frem from './componets/Frem';
import About from './componets/About';
import Service from './componets/Service';
 
 

function App() {
  return (
    <div className="App">
 
{/*  */}
<ScrollToTop/>
<Navbar/>
<Routes>
  <Route path='/' element={<Home/>}/>
  <Route path ='/log-in' element={<LogIn/>}/> 
  <Route path ='/invest1' element={<Invest1/>}/>
  <Route path ='/invest2' element={<Invest2/>}/>
  <Route path ='/invest3' element={<Invest3/>}/>
  <Route path ='/sum-bot' element={<Frem/>}/>
  <Route path ='/about-us' element={<About/>}/>
  <Route path ='/service' element={<Service/>}/>





   

  <Route/>
  <Route/>
  <Route/>

</Routes>
   
 
     <Footer/>
   
    </div>
  );
}

export default App;
