import React, { useEffect, useState } from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginLanding from './LoginLanding';
import LoginForm from './LoginForm';
import LoginQuesAns from './LoginQuesAns';

const LogIn = () => { 
  const [showScreen, setShowScreen] = useState('first')

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, [])  

  return (
    <div>
      {showScreen == "first" &&
         <LoginLanding screen={setShowScreen}/>
      }
      {showScreen == "second" &&
        <LoginForm screen={setShowScreen}/>
      }
      {showScreen == "third" &&
        <LoginQuesAns screen={setShowScreen}/>
      }
    </div>
  )
}

export default LogIn