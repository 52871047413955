import React, { useEffect, useState } from 'react'
import './login.css'
import ScrollToTop from '../../ScrollToTop';
import { useNavigate } from 'react-router-dom';

const qnsData = [
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
    {
        question: 'Do you believe that holding Bitcoin long-term is a good investment strategy?',
        option: [
            "ABC DEF GHI", "JKL MNO PQR", "STU VWX YZ"
        ],
    },
   

]

const LoginQuesAns = () => {
    const [qns, setQns] = useState();
    const [currentQuestion, setCurrentQuestion] = useState(0);

    useEffect(() => {
        const qnsWithPositions = qnsData.map((qnsItem) => {
            return {
                ...qnsItem,
                position: generateRandomPosition()
            };
        });
        setQns(qnsWithPositions)
    }, [qnsData])

    const generateRandomPosition = () => {
        // Generate random top between 15% and 65%
        const top = Math.floor(Math.random() * (40 - 15 + 1) + 15) + '%';
        // Generate random left between 10% and 65%
        const left = Math.floor(Math.random() * (50 - 10 + 1) + 10) + '%';

        const sTop = Math.floor(Math.random() * (90 - 10 + 1)) + 10 + '%';

        return { top, left, sTop };
    };

    const handleNext = () => {
        if (currentQuestion < qns.length - 1) {

            setCurrentQuestion(currentQuestion + 1);

        }
    };
    const handlePrev = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleSkip = () => {
        if (currentQuestion < qns.length - 1) {
            setCurrentQuestion(currentQuestion + 1);

        }
    };



    useEffect(() => {
        const qnsBackground = document.querySelector('.qnsSlide');
        if (qnsBackground) {
            const totalQuestions = qns?.length;
            const bgPositionX = (currentQuestion / (totalQuestions - 1)) * 100;
            qnsBackground.style.backgroundPositionX = `${bgPositionX}%`;
        }
    }, [currentQuestion, qns]);

    const navigate = useNavigate()

    return (
        <div className="qnsSection">
            <ScrollToTop />

            {qns?.length > 0 && (
                <div className="qnsSlide">
                    <div class='qnslN fs-3 fw-semibold '  >{currentQuestion + 1}</div>
                    {currentQuestion < qns.length - 1 ?
                        <div class='qnsrN fs-3 fw-semibold '  onClick={handleNext}>{currentQuestion + 2}</div>
                        :
                        <button className="sumBtn mfs-2 rounded-5 position-absolute bg-white qnsrB" onClick={()=>navigate("/invest1")} > Submit  </button>
                    }

                    <div className={`col-lg-4 col-md-6 col-11 rounded-4 border ansDivWind bg-white `} style={{ top: qns?.[currentQuestion]?.position?.top, left: qns?.[currentQuestion]?.position?.left }}>
                        <div className="col-11 m-auto my-4 text-start position-relative">
                            <div class=' position-absolute fs-3 fw-semibold p-3' style={{ top: "-20px", left: '-20px', opacity: '0.1', zIndex: '0', color: 'red' }}><p class='display-1 fwh'>{currentQuestion + 1}</p></div>

                            {/* <div class='qnsN fs-3 fw-semibold' style={{ top: qns?.[currentQuestion]?.position?.sTop }}>{currentQuestion + 1}</div> */}
                            <p class='fwsb'>{qns?.[currentQuestion]?.question}</p>
                            <div class='mt-2'>
                                {qns?.[currentQuestion]?.option?.map((e, oIndex) => (
                                    <div className="form-check" key={oIndex}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`RadioIndex${currentQuestion}`}
                                            id={`RadioIndex${oIndex}`}
                                        />
                                        <label
                                            className="form-check-label fwr"
                                            htmlFor={`RadioIndex${oIndex}`}
                                        >
                                            {e}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex gap-2 mt-4">
                                {currentQuestion > 0 && (
                                    <button
                                        className="sumBtn mfs-2  rounded-5"
                                        onClick={handlePrev}
                                    >
                                        Previous
                                    </button>
                                )}
                                {currentQuestion < qns.length - 1 ? (
                                    <>
                                        <button
                                            className="sumBtn mfs-2  rounded-5"
                                            onClick={handleSkip}
                                        >
                                            Skip
                                        </button>
                                        <button
                                            className="sumBtn mfs-2 rounded-5"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </button>
                                    </>
                                ) : (
                                    <button className="sumBtn mfs-2 rounded-5" onClick={()=>navigate("/invest1")}>
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>


                    <div className={`col-lg-4 col-md-6 col-11 rounded-4 border ansDivMobile bg-white `}  >
                        <div className="col-11 m-auto my-4 text-start position-relative">
                        <div class=' position-absolute fs-3 fw-semibold p-3' style={{ top: "-20px", left: '-20px', opacity: '0.1', zIndex: '0', color: 'red' }}><p class='display-1 fwh'>{currentQuestion + 1}</p></div>

                            {/* <div class='qnsN fs-3 fw-semibold'  >{currentQuestion + 1}</div> */}
                            <p class='fwsb'>{qns?.[currentQuestion]?.question}</p>
                            <div class='mt-2'>
                                {qns?.[currentQuestion]?.option?.map((e, oIndex) => (
                                    <div className="form-check" key={oIndex}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={`RadioIndex${currentQuestion}`}
                                            id={`RadioIndex${oIndex}`}
                                        />
                                        <label
                                            className="form-check-label fwr"
                                            htmlFor={`RadioIndex${oIndex}`}
                                        >
                                            {e}
                                        </label>
                                    </div>
                                ))}
                            </div>

                            <div className="d-flex gap-2 mt-4">
                                {currentQuestion > 0 && (
                                    <button
                                        className="sumBtn mfs-2  rounded-5"
                                        onClick={handlePrev}
                                    >
                                        Previous
                                    </button>
                                )}
                                {currentQuestion < qns.length - 1 ? (
                                    <>
                                        <button
                                            className="sumBtn mfs-2  rounded-5"
                                            onClick={handleSkip}
                                        >
                                            Skip
                                        </button>
                                        <button
                                            className="sumBtn mfs-2 rounded-5"
                                            onClick={handleNext}
                                        >
                                            Next
                                        </button>
                                    </>
                                ) : (
                                    <button className="sumBtn mfs-2 rounded-5">
                                        Submit
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LoginQuesAns