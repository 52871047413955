import React, { useEffect, useRef } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
    const footerRef = useRef(null);

    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    useEffect(() => {
        const div = document.querySelector(".footerAni");
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    div.classList.add("active")
                } else {
                    div.classList.remove("active");
                }
            },
            { rootMargin: "0px", threshold: 0.5 }
        );
        if (footerRef.current) {
            observer.observe(footerRef.current);
        }
        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    return (
        <div class=' position-relative' style={{ background: '#424242', overflow: 'hidden',zIndex:'1' }} ref={footerRef}>

            <div class='footerAni  col-12' > 
                <div class='col-sm-7 col-11 m-auto '>
                    <img src={require('../Images/footerBg.png')} style={{ width: '100%' }} alt="" />
                </div>

            </div>
            <br />
            <div class='col-11 row text-white m-auto gap-md-0 gap-3'>
                <div class='col-md-5'>
                    <div class=' col-md-10  text-start my-3'>

                        <img src={require('../Images/footerLogo.png')} style={{ height: '60px' }} alt="" />
                        <p class='mfs-2 mt-3 fwr'>3Sum is a revolutionary platform designed to make trading and investing accessible to everyone, especially beginners. Unlike traditional platforms that cater to advanced traders with complex tools.</p>
                        <button class='sumBtn mfs-4 my-4 rounded-5'>Contact Us</button>
                    </div>

                </div>
                <div class='col-md-7'>
                    <div class='row gap-sm-0 gap-3' >
                        <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                        </div>
                        <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                        </div>
                        <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                            <p class='mfs-2'>Pages</p>
                        </div>
                        <div className='col-sm-3 col-5 text-start'>
                            <p class='fwsb mfs-3'>Follow Us</p>
                            <div class='d-flex gap-1 align-items-center justify-content-around mfs-3 mt-2'>

                                <p class=''><i class="bi bi-facebook"></i></p>
                                <p class=''><i class="bi bi-instagram"></i></p>
                                <p class=''><i class="bi bi-twitter-x"></i></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <br /><br /><br />

        </div>
    )
}

export default Footer