import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './invest.css' 
import { FaStarOfLife } from 'react-icons/fa';
import { GiStarShuriken } from 'react-icons/gi';


const ourProduct = [
    {
        title: "Fx-Pro",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: '/invest1'
    },
    {
        title: "CMC Markets",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: '/invest2'
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "How to Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet Coach",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Wallstreet 101",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Forex UPS",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "FINLATO",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: ''
    },
    {
        title: "Track a Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: '/sum-bot'
    },
    {
        title: "Mark Dickinson",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you. Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. ',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProductMobile.jpg')}`,
        url: '/about-us'
    },
]

 const stars = () =>{
    let arr = []
    for(let i = 0 ; i< 60 ;i++){
        arr.push(
            <p class='d-flex gap-4 '>
                <span><FaStarOfLife /></span>
                <span><GiStarShuriken /></span> 
            </p>

        )
    }
    return arr;
 }


const Invest3 = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollPositionRight, setScrollPositionRight] = useState(0);


    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])


    const handleScroll = () => {
        setScrollPosition(window.scrollY);
        setScrollPositionRight(window.scrollY-1000)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const navigate = useNavigate()
    return (
        <div style={{ overflow: 'hidden' }}>
            <div class='col-11 m-auto d-flex align-items-center justify-content-center ' style={{ height: '100vh' }}>
                <div class='col-lg-8 text-start '>
                    <p class='mfs-8 fwsb'  data-aos="zoom-in">Let's <span style={{ color: '#FF823C' }}>Invest, Earn </span> and <span style={{ color: '#FF823C' }}>Generate Profit </span> </p>
                    <p class='tJustify fwt'  data-aos="fade-up">Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art .</p>
                    <button class='sumBtn mfs-4 rounded-5 mt-4' onClick={() => navigate('/log-in')}>Log in</button>
                </div>

            </div>

            <div>
                <div class='d-flex gap-4'  style={{ transform: `translateX(${scrollPositionRight}px)` }}>{stars()}</div>
                <div class='d-flex gap-4 my-3'  style={{ transform: `translateX(-${scrollPosition}px)` }}>{stars()}</div>
                <div class='d-flex gap-4' style={{ transform: `translateX(${scrollPositionRight}px)` }}>{stars()}</div> 
            </div>

            <div class='col-11 m-auto my-5 text-black' >
                <p class='mfs-8 fwb' data-aos="fade-up">Our Products</p>

                <div class='d-flex gap-3 flex-wrap my-5 '>
                    {ourProduct?.map((el, index) => {
                        return (
                            <Link
                                activeClass="active"
                                to={`investD${index}`}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <button data-aos="fade-up" class='btn btn-outline-dark text-nowrap rounded-5 fs-4 px-sm-4'>{el?.title}</button>
                            </Link>
                        )
                    })}
                </div>
            </div>

            <div class='col-11 m-auto'>
                {
                    ourProduct?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4   rounded-5 flex-column-reverse flex-lg-row p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden',border:'1px solid #FF823C',boxShadow: ' -8px -8px 0px  rgb(255,130,60) '}}>
                                
                                <div class='' style={{zIndex:'1'}}>
                                    <div class='text-start col-11 m-auto my-3 text-black'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-4 fwt my-2'>{el?.des}</p>
                                        <button class='sumBtn mfs-4 rounded-5 my-3' onClick={() => navigate(el?.url)}>Learn More</button>
                                        </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <br />
            <br />

        </div>
    )
}

export default Invest3