import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './invest.css'
import { FaArrowRightLong } from 'react-icons/fa6';

const moveText = ["How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server", "How to Invest", "FX Pro", "VPS Server",]

const ourProduct = [
    {
        title: "Fx-Pro",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: '/invest2'
    },
    {
        title: "CMC Markets",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: '/invest3'
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "How to Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Wallstreet Coach",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Finchat",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Wallstreet 101",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Forex UPS",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "FINLATO",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Track a Trade",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
    {
        title: "Mark Dickinson",
        des: 'Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art. Experience a world-class trading environment just for you.',
        bPTitle: "How to Invest",
        bPoint: [
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
            "Lorem Ipsum Dolor Sit Amet",
        ],
        img: `${require('../../Images/ourProducts2.png')}`,
        url: ''
    },
]


const Invest1 = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, [])

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const navigate = useNavigate()
    return (
        <div style={{ background: '#000000', overflow: 'hidden' }}>
            <div class='d-flex align-items-center justify-content-center' style={{ height: '100vh' }}>
                <div class='col-lg-7 col-11 m-auto text-white'>
                    <div>
                        <p class='mfs-8 fwsb' data-aos="zoom-in">Let's <span style={{ color: '#FF823C' }}>Invest</span> <br /> and <span class='px-4 rounded-4 investEarn'  > Earn.</span></p>
                        <p class='fwt mfs-3 col-11 m-auto tJustify mt-3' data-aos="fade-up">Experience a world-class trading environment curated just
                            for you. We’ve partnered with internationally recognized
                            brands and well-regulated institutions that offer state-of-the-art .</p>
                        <button class='sumBtn mfs-3 rounded-5 my-3' onClick={() => navigate('/log-in')}>Log in</button>

                    </div>
                </div>
            </div>
            <div style={{ background: '#FF823C', overflow: 'hidden', transform: 'rotate(10deg)', width: '120%', marginLeft: '-5%' }}>
                <div class='d-flex gap-4 p-2' style={{ transform: `translateX(-${scrollPosition}px)` }}>
                    {moveText?.map((el) => {
                        return (
                            <div class='d-flex align-items-center gap-5 flex-nowrap text-black'>
                                <p class='text-nowrap fs-2 fwh'>{el}</p>
                                <div class={'hamburger2 is-active'} id="hamburger2" >
                                    <span class={`lines`} style={{ background: '#000', height: '10px' }}></span>
                                    <span class={`lines`} style={{ background: '#000', height: '15px' }}></span>
                                    <span class={`lines`} style={{ background: '#000', height: '20px' }}></span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div class='col-11 m-auto text-white my-5' >
                <p class='mfs-8 fwb' data-aos="fade-up">Our Products</p>

                <div class='d-flex gap-3 flex-wrap  mt-5 '>
                    {ourProduct?.map((el, index) => {
                        return (
                            <Link
                                activeClass="active"
                                to={`investD${index}`}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <button data-aos="fade-up" class='btn btn-outline-light text-nowrap rounded-5 fs-4 px-sm-4'>{el?.title}</button>
                            </Link>
                        )
                    })}
                </div>
            </div>

            <div class='col-11 m-auto'>
                {
                    ourProduct?.map((el, index) => {
                        return (
                            <div data-aos="fade-up" class='row gap-lg-0 gap-4 border border-light rounded-5 p-0 m-0 mt-5' id={`investD${index}`} style={{ overflow: 'hidden', position: 'relative' }}>
                                {/* <div class='underText'><p class='mfs-8 fwh'>{el?.title}</p></div> */}
                                <div class='col-lg-7' style={{ zIndex: '1' }}>
                                    <div class='text-start col-11 m-auto my-3 text-white'>
                                        <p class='fs-1 fweb'>{el?.title}</p>
                                        <p class='fs-5 fwt my-2'>{el?.des}</p>
                                        <p class='fwsb'>{el?.bPTitle}</p>
                                        <ul>
                                            {el?.bPoint?.map((e) => {
                                                return (
                                                    <li class='fwt mt-1'>
                                                        {e}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <button class='sumBtn mfs-3 rounded-5' onClick={() => navigate(el?.url)}>Learn More</button>

                                    </div>
                                </div>
                                <div class='col-lg-5 rounded-5 p-0 proImgeDiv' style={{ overflow: 'hidden', position: 'relative', zIndex: '1' }}>
                                    <div class='proImgeIcon' onClick={() => navigate(el?.url)}> <p class='fs-3 fw-bold text-white'><FaArrowRightLong /></p> </div>
                                    <img src={el?.img} style={{ width: '100%', height: '100%' }} alt="" onClick={() => navigate(el?.url)} />
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default Invest1