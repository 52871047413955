// Services.js
import React, { useEffect, useState, useRef } from 'react';
import './services.css';
import Lottie from 'lottie-react';

const Services = () => {
  const [activeSection, setActiveSection] = useState(-1);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const containerHeight = container.getBoundingClientRect().height;
      const sectionHeight = containerHeight / 6;

      // Determine the active section based on scroll position
      const scrollPosition = window.scrollY - (containerTop + window.scrollY);
      
      if (scrollPosition < 0) {
        setActiveSection(0);
      } else if (scrollPosition < sectionHeight) {
        setActiveSection(0);
      } else if (scrollPosition < 3 * sectionHeight) {
        setActiveSection(1);
      } else {
        setActiveSection(2);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const serviceItems = [
    {
      title: "Specially Curated Trading Environment",
      text: "Experience a world-class trading environment curated just for you. We’ve partnered with internationally recognized brands and well-regulated institutions that offer state-of-the-art technology, ensuring you trade with confidence and security.",
      animation: require('../../Images/First.json'),
    },
    {
      title: "Automated Copy Trading with Global Experts",
      text: "Gain access to over 500 expert traders from around the world. Review their strategies, performance history, and rankings before committing as little as $200 per trader. Diversify your investments across different asset classes, traders and strategies.",
      animation: require('../../Images/Second.json'),
    },
    {
      title: "Meet SumBot3.0 - AI-Enabled Financial Research",
      text: "Unlock the power of Al with SumBot3.0, your personal financial research assistant. Trained on billions of data points, SumBot3.0 delivers deep market insights and analysis to keep you ahead in the trading game.",
      animation: require('../../Images/Third.json'),
    }
  ];

  return (
    <div className="service-container" ref={containerRef}>
      <div className='service-container-sticky'>
        {serviceItems.map((item, index) => (
          <div
            key={index}
            className={`sticky-section ${index === activeSection ? 'active' : ''} ${index < activeSection ? 'past' : ''}`}
          >
            <p className="service-header mfs-2 fwr">Services {index+1}</p>
            <div>
              <p className={`${index === activeSection ? 'mfs-5' : 'mfs-4'} fwr`}>{item.title}</p>
              <p className={`service-description ${index === activeSection ? 'active mfs-2' : 'mfs-1'} fwt`}>{item.text}</p>
              <div className={`serviceImageBox ${index === activeSection ? 'active' : ''}`}>
                <Lottie animationData={item.animation} loop={true} style={{ height: '100%', width: '100%' }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
