import React, { useEffect, useState } from 'react'
import { Country, State, City } from "country-state-city";
import ScrollToTop from '../../ScrollToTop';
import './login.css'


const LoginForm = ({ screen }) => {

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email ,  setEmail] = useState("")

    const [showFild, setShowFild] = useState("email")

    useEffect(() => {
        const countryList = Country.getAllCountries();
        setCountries(countryList);
    }, []);

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setSelectedCountry(countryCode);
        const stateList = State.getStatesOfCountry(countryCode);
        setStates(stateList);
        setSelectedState("");
        setCities([]);
    };





    console.log(countries);

    return (
        <div class='loginBg'>
            <ScrollToTop />

            <div class='col-lg-8 col-11'>
                <p class='display-4 fwsb org ' >Let's Connect Together</p>
                <p class='fwt col-10 m-auto mt-2'>Experience a world-class trading environment curated just for you. We’ve partnered with internationally.</p>

                {showFild === "email" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter Email</p>
                        <p class='fwt col-11 m-auto'>We will send you an One Time Passcode via this email address</p>
                        <div class="col-lg-6 col-11 m-auto  form-floating mt-3  p-0 border-danger">
                            <input type="email" class="form-control " id="gmail" placeholder="sum@gmail.com" value={email} onChange={(e)=>setEmail(e.target.value)} style={{ border: '0.5px solid #FF823C' }} />
                            <label for="gmail">Email address</label>
                        </div>
                        <div class='mt-4'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => setShowFild("otp")} >Verify Email</button>
                        </div>
                        <br />
                    </div>
                }

                {
                    showFild === "otp" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter OTP</p>
                        <p class='fwt col-11 m-auto'>Send you an One Time Passcode in <span class='fwsb'>{email}</span>  email address. <span class='text-danger' style={{cursor:'pointer',textDecoration:'underline'}} onClick={() => setShowFild("email")}>Change Email ?</span></p> 
                        <div class="col-lg-6 col-11 m-auto  form-floating mt-3  p-0 border-danger">
                            <input type="email" class="form-control " id="otp" placeholder="OTP" style={{ border: '0.5px solid #FF823C' }} />
                            <label for="otp">OTP</label>
                        </div>
                        <p class='m-auto fw-bold my-2' style={{ cursor: 'pointer' }} onClick={() => setShowFild("otp")}>Didn’t get it? Resend Code</p>
                        <div class='mt-3'>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => setShowFild("details")} >Verify OTP</button>
                        </div>
                        <br />
                    </div>
                }

                {
                    showFild === "details" &&
                    <div class='rounded-5 bg-white mt-4' style={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 15px' }}>
                        <br />
                        <p class='fs-1 fwsb org'>Enter Your Details</p>
                        <div class='row col-11 m-auto align-items-center justify-content-lg-around  justify-content-center'>
                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="firstName" placeholder="First Name" />
                                <label for="firstName">Full Name</label>
                            </div>

                            <div class="  col-lg-5 col-11 form-floating mt-4  p-0">
                                <select
                                    id="country"
                                    name="country"
                                    className="form-control"
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map((country) => (
                                        <option key={country.isoCode} value={country.isoCode}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                                <label for="country">Country</label>
                            </div>
                            <div class="col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="text" class="form-control" id="city" placeholder="City" />
                                <label for="city">City</label>
                            </div>

                            <div class=" col-lg-5 col-11 form-floating mt-4  p-0">
                                <input type="tel" class="form-control" id="number" maxLength={10} placeholder="Phone/Mobile Number" />
                                <label for="number">Phone/Mobile Number</label>
                            </div>
                        </div>

                        <div class='mt-4  '>
                            <button class='sumBtn mfs-4   rounded-5' onClick={() => screen("third")} >Submit</button>
                        </div>
                        <br />
                    </div>
                }

            </div>

        </div>
    )
}

export default LoginForm



{/* <div class='col-lg-6 col-11 m-auto mt-5 row justify-content-lg-around'> 
                    <div class="form-floating mt-3  p-0">
                        <input type="text" class="form-control" id="firstName" placeholder="First Name" />
                        <label for="firstName">Full Name</label>
                    </div> 
                    <div class="form-floating mt-3  p-0">
                        <input type="email" class="form-control" id="gmail" placeholder="sum@gmail.com" />
                        <label for="gmail">Email address</label>
                    </div>  
                    <div class="form-floating mt-3  p-0">
                        <select
                            id="country"
                            name="country"
                            className="form-control"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                        >
                            <option value="">Select Country</option>
                            {countries.map((country) => (
                                <option key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                        <label for="country">Country</label>
                    </div> 
                    <div class="form-floating mt-3  p-0">
                        <input type="text" class="form-control" id="city" placeholder="City" />
                        <label for="city">City</label>
                    </div>

                    <div class="form-floating mt-3  p-0">
                        <input type="tel" class="form-control" id="number" maxLength={10} placeholder="Phone/Mobile Number" />
                        <label for="number">Phone/Mobile Number</label>
                    </div>

                    <div class='my-5'>
                        <button class='sumBtn mfs-4   rounded-5' style={{ zIndex: '100px' }} onClick={() => screen('third')} >Countinu</button>
                    </div> 

                </div> */}